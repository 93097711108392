<template>
  <component
    :is="component"
    :payment-type="paymentType"
    :order-id="orderId"
    :mode="mode"
    v-if="component"
    :paymentDisabled="paymentDisabled"
  />
</template>
<script>
import gateway from "~/mixins/gateway";
export default {
  name: "GatewayWriter",
  mixins: [gateway],
  props: {
    componentName: { type: String, required: true },
    paymentDisabled: { type: Boolean, default: false }
  },
  data() {
    return {
      component: null
    };
  },
  computed: {
    loader() {
      return () => import("./" + this.componentName);
    }
  },
  mounted() {
    this.loader()
      .then(() => {
        this.component = () => this.loader();
      })
      .catch(() => {});
  }
};
</script>
