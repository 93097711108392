<template>
  <div class="payment">
    <CategoryTitle :category="category" />
    <v-container>
      <Breadcrumb class="hidden-sm-and-down px-0" :items="breadcrumbs" />
    </v-container>
    <v-container v-if="headerBanner.length > 1">
      <ProposalSlider :proposals="headerBanner" />
    </v-container>
    <v-container class="pt-0">
      <v-row>
        <v-col cols="12" md="8" class="cart-info pa-0 pa-sm-3">
          <div>
            <CartInfoList class="cart-info-list py-8" :isCart="true" />
            <!-- <v-container class="terms">
              <v-checkbox
                light
                hide-details
                v-model="acceptConditions"
                :value="acceptConditions"
                class="mb-3"
              >
                <template v-slot:label>
                  <span class="label"
                    >Ho letto le
                    <a @click.stop.prevent="goToTerms">condizioni di vendita</a>
                    *</span
                  >
                </template>
              </v-checkbox>
              <span class="info-text">{{
                $t("payment.termsAcceptanceInfo")
              }}</span>
            </v-container> -->
          </div>

          <!-- visualizzazione mobile -->
          <div class="summary-cards">
            <CheckoutSummary
              class="payment-summary d-flex d-md-none flex-column"
              :orderCart="cart"
            />
          </div>

          <PaymentTypeList :cart="cart" mode="cart" />
        </v-col>
        <!-- visualizzazione desktop -->
        <v-col
          cols="12"
          md="4"
          class="summary-cards d-none d-md-flex flex-column"
          v-if="cart.shippingAddress"
        >
          <CheckoutSummary
            class="payment-summary d-flex flex-column"
            :orderCart="cart"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-spacer class="spacer-scroll"></v-spacer>
    <v-container v-if="footerBanner.length > 1">
      <ProposalSlider :proposals="footerBanner" />
    </v-container>
  </div>
</template>
<script>
import CartService from "~/service/cartService";
import Breadcrumb from "@/components/navigation/Breadcrumb";
import AnalyticsService from "~/service/analyticsService";
import CategoryTitle from "@/components/category/CategoryTitle";
import CheckoutSummary from "@/components/cart/CheckoutSummary";
import CartInfoList from "@/components/cart/CartInfoList";
import PaymentTypeList from "@/components/payment/PaymentTypeList.vue";
// import PackagesTypeList from "@/components/payment/PackagesTypeList";

import ProposalSlider from "@/components/proposal/ProposalSlider";
import { mapState, mapGetters } from "vuex";
import login from "~/mixins/login";
import productMixins from "~/mixins/product";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";
// import RegistrationService from "~/service/userService";

import { mapProposal } from "~/service/ebsn.js";

export default {
  name: "Payment",
  data() {
    return {
      paymentTypeList: [],
      packagesList: []
    };
  },
  components: {
    PaymentTypeList,
    Breadcrumb,
    CategoryTitle,
    CheckoutSummary,
    CartInfoList,
    ProposalSlider
  },
  mixins: [login, productMixins, cartInfo, categoryMixins],
  computed: {
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Payment",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      return breadCrumbs;
    },
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      getCartStatus: "cart/getCartStatus"
    }),
    ...mapProposal({
      headerBanner: "header-banner",
      footerBanner: "footer-banner"
    })
  },
  methods: {
    goToTerms() {
      window.open("/page/condizioni-di-vendita/", "_blank");
    },
    fetchPackages() {
      CartService.getPackages(this.warehouseId, false).then(packagesList => {
        if (packagesList) {
          this.packagesList = packagesList;

          this.packagesList = this.packagesList.concat([
            {
              available: 10,
              productId: 0,
              name: "Nessun sacchetto",
              shortDescr: "",
              description: "",
              mediaURL: "",
              highMediaURL: ""
            }
          ]);
        }
      });
    }
  },
  mounted() {
    this.fetchPackages();

    AnalyticsService.checkoutProgress(this.cart);

    // this.getUserDetails();
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.cart && vm.cart.totalItems > 0) {
        next();
      } else {
        next("/");
      }
    });
  }
};
</script>
